var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LeaveList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          headers: _vm.headers,
          extraParams: _vm.extraParams,
          hasOperateColumn: false,
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "返回" },
                  on: { click: _vm.back },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }