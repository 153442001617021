import { mapHelper } from 'common/utils'

// 表单状态
const formStatus = [
  {
    text: '已保存',
    value: 0
  },
  {
    text: '已提交',
    value: 1
  },
  {
    text: '正在审批',
    value: 2
  },
  {
    text: '审批完成',
    value: 3
  },
  {
    text: '已撤回',
    value: 4
  },
]

const {
  map: formStatusMap,
  setOps: formStatusOps
} = mapHelper.setMap(formStatus)

export {
    formStatusMap,
    formStatusOps
}