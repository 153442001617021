<template>
  <div class="LeaveList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :headers="headers"
      :extraParams="extraParams"
      :hasOperateColumn="false"
    >
      <template #headerSlot>
        <v-button text="返回"  @click="back" />
      </template>
      <template #operateSlot>
        
      </template>
    </list>
  </div>
</template>

<script>
import { getHistoryFlowListURL } from './api'
import { formStatusOps, formStatusMap } from './map'

export default {
  name: 'HistoryFlowList',
  data () {
    return {
      searchUrl: getHistoryFlowListURL,
      maxDate: new Date(),
      extraParams: {
          procInstId: ''
      },
      headers: [
        {
          prop: 'name',
          label: '任务名称'
        },
        {
          prop: 'assignees',
          label: '处理人',
          formatter: row => {
            for (const key in row.assignees) {
                if (Object.hasOwnProperty.call(row.assignees, key)) {
                    const element = row.assignees[key];
                    if(element.isExecutor) {
                        return element.username
                    }
                }
            }
          }
        },
        {
          prop: '',
          label: '候选处理人',
          formatter: row => {
            let assignees=[]
            for (const key in row.assignees) {
                if (Object.hasOwnProperty.call(row.assignees, key)) {
                    const element = row.assignees[key];
                    if(!element.isExecutor) {
                        assignees.push(element.username)
                    }
                }
            }
            return assignees.join(',')
          }
        },
        {
          prop: 'deleteReason',
          label: '审批操作'
        },
        {
          prop: 'comment',
          label: '审批意见'
        },
        {
          prop: 'duration',
          label: '耗时',
          formatter: row => {
            return this.millsToTime(row.duration)
          }
        },
        // {
        //   prop: 'createTime',
        //   label: '创建时间',
        //   formatter: row => formStatusMap[row.status]
        // },
        {
          prop: 'endTime',
          label: '完成时间'
        },
        {
          prop: '',
          label: '审批状态',
          formatter: row => {
            if(row.endTime) {
                return "已办理"
            }
            return "待处理"
          }
        },
      ]
    }
  },
  computed: {
    visible () {
      const userType = this.$store.state.userInfo.userType
      return userType === '106'
    },
    getUserId () {
      return this.$store.state.userInfo.id
    },
    
  },
  mounted() {
    console.log(this.$store.state.userInfo)
  },
  created() {
      let { procInstId } = this.$route.query
      this.extraParams.procInstId = procInstId
      this.searchUrl = `${getHistoryFlowListURL}/${procInstId}`
  },
  methods: {
      millsToTime (mills) {
        if (!mills) {
            return ''
        }
        let s = mills / 1000
        if (s < 60) {
            return s.toFixed(0) + ' 秒'
        }
        let m = s / 60
        if (m < 60) {
            return m.toFixed(0) + ' 分钟'
        }
        let h = m / 60
        if (h < 24) {
            return h.toFixed(0) + ' 小时'
        }
        let d = h / 24
        if (d < 30) {
            return d.toFixed(0) + ' 天'
        }
        let month = d / 30
        if (month < 12) {
            return month.toFixed(0) + ' 个月'
        }
        let year = month / 12
        return year.toFixed(0) + ' 年'
    },
    back() {
        this.$router.go(-1)
    }

  }
}
</script>
